.blog-site {
  background: #f5f5f5 !important;
  #root {
    display: flex;
    justify-content: center;
  }
  #app {
    max-width: 1024px;
  }
  p.author {
    color: var(--author-color) !important;
  }

  .blog-container {
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1.title {
      text-align: center;
      font-size: 35px;
      font-weight: bold;
      margin-top: 3%;
      margin-left: -80px;
      margin-right: -80px;
    }

    h1 {
      font-family: "Oswald", sans-serif;
      font-size: 30px;
    }

    p {
      font-family: "Lato", sans-serif;
      font-size: 22px;
    }
  }

  .message-wrapper {
    margin-bottom: 30px;
    width: 90%;
    line-height: 24px;
  }

  .tittle-footer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .user-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    .user {
      display: flex;
      align-items: center;
      p:first-of-type {
        margin: 0px !important;
        color: var(--signup-button-text);
      }
      i.fa {
        margin-right: 20px;
      }
    }
    .soc-med {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      i.fa:not(:last-of-type) {
        margin-right: 20px;
      }
    }

    i.fa {
      color: var(--signup-button-text);
      font-size: 18px;
    }
  }

  .sign-up-container {
    display: flex;
    align-items: center;
  }

  .sign-up-container-box {
    border-color: var(--signup-button-background);
  }

  .sign-up-container button {
    /* white-space: nowrap; */
    font-size: 18px;
    border-radius: 10;
    color: var(--signup-button-text);
    /* flex-direction: column; */
    background-color: var(--signup-button-background);
    padding: 10px 20px;
    height: 40px;
    cursor: pointer;
  }

  .sign-up-container button:hover {
    background-color: var(--signup-button-hover-background);
  }

  .sign-up-container input {
    /* margin-right: 20px; */
  }

  @media (max-width: 768px) {
    .blog-container {
      width: 100% !important;
      padding-left: 50px;
      padding-right: 50px;

      h1.title {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    .message-wrapper {
      width: 100%;
    }

    .sign-up-container {
      flex-direction: column;
    }

    .sign-up-container-box {
      border-color: var(--signup-button-background);
    }

    .sign-up-container input {
      margin-right: 0px;
    }

    .sign-up-container button {
      margin-top: 20px;
      width: 100%;
    }
  }

  #_form_1_ {
    font-size: 14px;
    line-height: 1.6;
    font-family: arial, helvetica, sans-serif;
    margin: 0;
  }
  #_form_1_ * {
    outline: 0;
  }
  ._form_hide {
    display: none;
    visibility: hidden;
  }
  ._form_show {
    display: block;
    visibility: visible;
  }
  #_form_1_._form-top {
    top: 0;
  }
  #_form_1_._form-bottom {
    bottom: 0;
  }
  #_form_1_._form-left {
    left: 0;
  }
  #_form_1_._form-right {
    right: 0;
  }
  #_form_1_ input[type="text"],
  #_form_1_ input[type="date"],
  #_form_1_ textarea {
    padding: 6px;
    height: auto;
    border: #979797 1px solid;
    border-radius: 4px;
    color: #000 !important;
    font-size: 14px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  #_form_1_ textarea {
    resize: none;
  }
  #_form_1_ ._submit {
    -webkit-appearance: none;
    cursor: pointer;
    font-family: arial, sans-serif;
    font-size: 14px;
    text-align: center;
    background: #333 !important;
    border: 0 !important;
    -moz-border-radius: 4px !important;
    -webkit-border-radius: 4px !important;
    border-radius: 4px !important;
    color: var(--signup-button-text) !important;
    padding: 10px !important;
  }
  #_form_1_ ._close-icon {
    cursor: pointer;
    background-image: url("https://d226aj4ao1t61q.cloudfront.net/esfkyjh1u_forms-close-dark.png");
    background-repeat: no-repeat;
    background-size: 14.2px 14.2px;
    position: absolute;
    display: block;
    top: 11px;
    right: 9px;
    overflow: hidden;
    width: 16.2px;
    height: 16.2px;
  }
  #_form_1_ ._close-icon:before {
    position: relative;
  }
  #_form_1_ ._form-body {
    margin-bottom: 30px;
  }
  #_form_1_ ._form-image-left {
    width: 150px;
    float: left;
  }
  #_form_1_ ._form-content-right {
    margin-left: 164px;
  }
  #_form_1_ ._form-branding {
    color: var(--signup-button-text);
    font-size: 10px;
    clear: both;
    text-align: left;
    margin-top: 30px;
    font-weight: 100;
  }
  #_form_1_ ._form-branding ._logo {
    display: block;
    width: 130px;
    height: 14px;
    margin-top: 6px;
    background-image: url("https://d226aj4ao1t61q.cloudfront.net/hh9ujqgv5_aclogo_li.png");
    background-size: 130px auto;
    background-repeat: no-repeat;
  }
  #_form_1_ ._form-label,
  #_form_1_ ._form_element ._form-label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  #_form_1_._dark ._form-branding {
    color: #333;
  }
  #_form_1_._dark ._form-branding ._logo {
    background-image: url("https://d226aj4ao1t61q.cloudfront.net/jftq2c8s_aclogo_dk.png");
  }
  #_form_1_ ._form_element {
    position: relative;
    margin-bottom: 10px;
    font-size: 0;
    max-width: 100%;
  }
  #_form_1_ ._form_element * {
    font-size: 14px;
  }
  #_form_1_ ._form_element._clear {
    clear: both;
    width: 100%;
    float: none;
  }
  #_form_1_ ._form_element._clear:after {
    clear: left;
  }
  #_form_1_ ._form_element input[type="text"],
  #_form_1_ ._form_element input[type="date"],
  #_form_1_ ._form_element select,
  #_form_1_ ._form_element textarea:not(.g-recaptcha-response) {
    display: block;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  #_form_1_ ._field-wrapper {
    position: relative;
  }
  #_form_1_ ._inline-style {
    float: left;
  }
  #_form_1_ ._inline-style input[type="text"] {
    width: 150px;
  }
  #_form_1_ ._inline-style:not(._clear) + ._inline-style:not(._clear) {
    margin-left: 20px;
  }
  #_form_1_ ._form_element img._form-image {
    max-width: 100%;
  }
  #_form_1_ ._clear-element {
    clear: left;
  }
  #_form_1_ ._full_width {
    width: 100%;
  }
  #_form_1_ ._form_full_field {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  #_form_1_ input[type="text"]._has_error,
  #_form_1_ textarea._has_error {
    border: #f37c7b 1px solid;
  }
  #_form_1_ input[type="checkbox"]._has_error {
    outline: #f37c7b 1px solid;
  }
  #_form_1_ ._error {
    display: block;
    position: absolute;
    font-size: 14px;
    z-index: 10000001;
  }
  #_form_1_ ._error._above {
    padding-bottom: 4px;
    bottom: 39px;
    right: 0;
  }
  #_form_1_ ._error._below {
    padding-top: 4px;
    top: 100%;
    right: 0;
  }
  #_form_1_ ._error._above ._error-arrow {
    bottom: 0;
    right: 15px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #f37c7b;
  }
  #_form_1_ ._error._below ._error-arrow {
    top: 0;
    right: 15px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #f37c7b;
  }
  #_form_1_ ._error-inner {
    padding: 8px 12px;
    background-color: #f37c7b;
    font-size: 14px;
    font-family: arial, sans-serif;
    color: var(--signup-button-text);
    text-align: center;
    text-decoration: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  #_form_1_ ._error-inner._form_error {
    margin-bottom: 5px;
    text-align: left;
  }
  #_form_1_ ._button-wrapper ._error-inner._form_error {
    position: static;
  }
  #_form_1_ ._error-inner._no_arrow {
    margin-bottom: 10px;
  }
  #_form_1_ ._error-arrow {
    position: absolute;
    width: 0;
    height: 0;
  }
  #_form_1_ ._error-html {
    margin-bottom: 10px;
  }
  .pika-single {
    z-index: 10000001 !important;
  }

  @media (max-width: 425px) {
    .blog-container {
      padding-left: 10px;
      padding-right: 10px;

      h1 {
        font-size: 20px;
      }

      h1.title {
        font-size: 25px;
      }

      p {
        font-size: 15px;
      }
    }
  }

  @media all and (min-width: 320px) and (max-width: 667px) {
    ::-webkit-scrollbar {
      display: none;
    }
    #_form_1_ {
      margin: 0;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      box-sizing: border-box;
    }
    #_form_1_ * {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 1em;
    }
    #_form_1_ ._form-content {
      margin: 0;
      width: 100%;
    }
    #_form_1_ ._form-inner {
      display: block;
      min-width: 100%;
    }
    #_form_1_ ._form-title,
    #_form_1_ ._inline-style {
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
    }
    #_form_1_ ._form-title {
      font-size: 1.2em;
    }
    #_form_1_ ._form_element {
      margin: 0 0 20px;
      padding: 0;
      width: 100%;
    }
    #_form_1_ ._form-element,
    #_form_1_ ._inline-style,
    #_form_1_ input[type="text"],
    #_form_1_ label,
    #_form_1_ p,
    #_form_1_ textarea:not(.g-recaptcha-response) {
      float: none;
      display: block;
      width: 100%;
    }
    #_form_1_ ._row._checkbox-radio label {
      display: inline;
    }
    #_form_1_ ._row,
    #_form_1_ p,
    #_form_1_ label {
      margin-bottom: 0.7em;
      width: 100%;
    }
    #_form_1_ ._row input[type="checkbox"],
    #_form_1_ ._row input[type="radio"] {
      margin: 0 !important;
      vertical-align: middle !important;
    }
    #_form_1_ ._row input[type="checkbox"] + span label {
      display: inline;
    }
    #_form_1_ ._row span label {
      margin: 0 !important;
      width: initial !important;
      vertical-align: middle !important;
    }
    #_form_1_ ._form-image {
      max-width: 100%;
      height: auto !important;
    }
    #_form_1_ input[type="text"] {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 16px;
      line-height: 1.3em;
      -webkit-appearance: none;
    }
    #_form_1_ input[type="radio"],
    #_form_1_ input[type="checkbox"] {
      display: inline-block;
      width: 1.3em;
      height: 1.3em;
      font-size: 1em;
      margin: 0 0.3em 0 0;
      vertical-align: baseline;
    }
    #_form_1_ button[type="submit"] {
      padding: 20px;
      font-size: 1.5em;
    }
    #_form_1_ ._inline-style {
      margin: 20px 0 0 !important;
    }
  }
  #_form_1_ {
    position: relative;
    text-align: left;
    margin: 25px auto 0;
    padding: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *zoom: 1;
    background: var(--signup-button-background) !important;
    border: 0px solid #b0b0b0 !important;
    width: 500px;
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    border-radius: 0px !important;
    color: #000 !important;
  }
  #_form_1_ ._form-title {
    font-size: 22px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 0;
  }
  #_form_1_:before,
  #_form_1_:after {
    content: " ";
    display: table;
  }
  #_form_1_:after {
    clear: both;
  }
  #_form_1_._inline-style {
    width: auto;
    display: inline-block;
  }
  #_form_1_._inline-style input[type="text"],
  #_form_1_._inline-style input[type="date"] {
    padding: 10px 12px;
  }
  #_form_1_._inline-style button._inline-style {
    position: relative;
    top: 27px;
  }
  #_form_1_._inline-style p {
    margin: 0;
  }
  #_form_1_._inline-style ._button-wrapper {
    position: relative;
    margin: 27px 12.5px 0 20px;
  }
  #_form_1_ ._form-thank-you {
    position: relative;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 18px;
  }
  @media all and (min-width: 320px) and (max-width: 667px) {
    #_form_1_._inline-form._inline-style ._inline-style._button-wrapper {
      margin-top: 20px !important;
      margin-left: 0 !important;
    }
  }

  @media (min-width: 1024px) {
    h1.title {
      font-size: 45px !important;
    }

    h1 {
      font-size: 35px !important;
      margin-top: 35px !important;
      margin-bottom: 35px !important;
    }

    p {
      font-size: 25px !important;
    }
  }
}
