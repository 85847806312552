$white: #ffffff;
$black: #000000;
$orange: #2d3943;
$orange-1: darken($white, 4%);
$orange-2: #f48122;

.orange-theme {
  // blog page
  // --blog-border-top: #{$orange-1};
  --blog-success-text: #{$black};

  //signup button
  --author-color: #{$orange};
  --signup-button-text: #{$white};
  --signup-button-background: #{$orange};
  --signup-button-hover-background: #{darken($orange, 5%)};

  //calculator page
  //get started button
  --signup-calc-button-text: #{$white};
  --signup-calc-button-background: #{$orange};
  --signup-calc-button-hover-background: #{darken($orange, 5%)};

  //graph
  --graph-tooltip: #{$orange-1};
  --graph-text-standard: #{$orange-2};
  --graph-text-biweekly: #{$orange-1};
  --graph-bar-standard: #{$orange-2};
  --graph-bar-biweekly: #{$orange};
  --graph-line-standard: #{$orange-2};
  --graph-line-biweekly: #{$orange};
  --graph-background: #{$orange-1};
  --graph-header-background: #{$orange-1};
  --graph-header-text: #{$black};
  --graph-border: #{$orange};

  //mortgage-term option
  --mortgage-term-selected-text: #{$white};
  --mortgage-term-selected-background: #{$orange-2};

  //metric table
  --table-border: #{$orange-1};
  --table-emphasize: #{$orange-1};
  --table-emphasize-background: #{$orange};
  --table-header-background: #{lighten($orange, 5%)};
  --table-body-background: #{$white};
  --table-body-text: #{$orange};
  --table-td: #{$white};
  --table-td-first-text: #{$white};
  --table-td-first-background: #{lighten($orange, 10%)};
  --table-td-last-text: #{$orange};
  --table-biweekly-text: #{$orange};

  --confirm-outline: var(--green-1);

  // calculator header
  --app-title: #{$black};
  --app-body-text: #{$black};
  --app-body-background: #{$orange-1};
  --app-outer-background: #{$orange-1};

  //pre question buttons
  --button-back-background: #{$orange};
  --button-back-text: #{$white};
  --button-continue-background: #{$orange};
  --button-continue-text: #{$white};

  //breadcrumbs
  --breadcrumb-active-background: #{$orange};
  --breadcrumb-active-text: #{$white};

  // sticky header message
  --fixed-msg-a-background: #{$orange-1};
  --fixed-msg-a-text: #{$black};
  --fixed-msg-a-hover-background: #{$orange-2};
  --fixed-msg-a-hover-text: #{$black};
  --fixed-msg-background: #{$orange};
  --fixed-msg-text: #{$white};

  --el-small: #{$orange};
  --letter-border: #{$orange-2};

  --pre-question-border: #{$orange-2};

  --radio: #{$orange};
}
