$white: #ffffff;
$blue: #0a6dfc;
$black: #76746a;
$blue-1: darken($white, 4%);
$blue-2: darken($white, 1%);

.light-blue-theme {
  // blog page
  // --blog-border-top: #{$blue-1};
  --blog-success-text: #{$blue};

  //signup button
  --author-color: #{$blue};
  --signup-button-text: #{$white};
  --signup-button-background: #{$blue};
  --signup-button-hover-background: #{darken($blue, 5%)};

  //calculator page
  //get started button
  --signup-calc-button-text: #{$blue-1};
  --signup-calc-button-background: #{$blue};
  --signup-calc-button-hover-background: #{darken($blue, 5%)};

  //graph
  --graph-tooltip: #{$blue-1};
  --graph-text-standard: #{lighten($blue, 20%)};
  --graph-text-biweekly: #{$blue};
  --graph-bar-standard: #{lighten($blue, 20%)};
  --graph-bar-biweekly: #{$blue};
  --graph-line-standard: #{lighten($blue, 20%)};
  --graph-line-biweekly: #{$blue};
  --graph-background: #{$blue-1};
  --graph-header-background: #{$blue-1};
  --graph-header-text: #{$blue};
  --graph-border: #{$blue};

  //mortgage-term option
  --mortgage-term-selected-text: #{$white};
  --mortgage-term-selected-background: #{$blue};

  //metric table
  --table-border: #{$blue-1};
  --table-emphasize: #{$blue-1};
  --table-emphasize-background: #{$blue};
  --table-header-background: #{lighten($blue, 5%)};
  --table-body-background: #{$white};
  --table-body-text: #{$blue};
  --table-td: #{$white};
  --table-td-first-text: #{$white};
  --table-td-first-background: #{lighten($blue, 10%)};
  --table-td-last-text: #{$blue};
  --table-biweekly-text: #{$blue};

  --confirm-outline: var(--green-1);

  // calculator header
  --app-title: #{$black};
  --app-body-text: #{$black};
  --app-body-background: #{$blue-1};
  --app-outer-background: #{$blue-1};

  //pre question buttons
  --button-back-background: #{$blue};
  --button-back-text: #{$blue-2};
  --button-continue-background: #{$blue};
  --button-continue-text: #{$blue-2};

  //breadcrumbs
  --breadcrumb-active-background: #{$blue-1};
  --breadcrumb-active-text: #{$blue};

  // sticky header message
  --fixed-msg-a-background: #{$blue-1};
  --fixed-msg-a-text: #{$blue};
  --fixed-msg-a-hover-background: #{$blue-2};
  --fixed-msg-a-hover-text: #{$blue};
  --fixed-msg-background: #{$blue};
  --fixed-msg-text: #{$blue-1};

  --el-small: #{$blue};
  --letter-border: #{$blue};

  --pre-question-border: #{$blue};

  --radio: #{$blue};
}
