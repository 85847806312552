:root {
  --maroon: #8d1f55;
  --maroon-1: #ffffff;
}

@import "./themes/default.scss";
@import "./themes/light-green.scss";
@import "./themes/light-blue.scss";
@import "./themes/green.scss";
@import "./themes/light-red.scss";
@import "./themes/orange.scss";
@import "./themes/red.scss";
@import "./themes/blue.scss";

html {
  font-size: 16px;
}

body {
  font-family: "Nunito", sans-serif;
  height: 1300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/var(--maroon)+0,ef6b80+100 */
  background: var(--app-body-background) !important; /* Old browsers */

  color: var(--app-body-text) !important;
}

.input {
  padding: 10px;
  border-radius: 0.5rem;
  font-size: 14px;
}

p {
  font-size: 16px;
  margin-bottom: 10px !important;
}

h4 {
  font-size: 1.5rem;
}

small {
  font-weight: lighter;
  color: gray;
  //margin: 0;
  font-size: 8px;
  //font-size: 6px;
  //padding-top: 50px;
}

.flex {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;

  width: 90%;
  margin-left: 30px;
  float: left;

  align-content: space-between;
  flex-wrap: wrap;
}
.box {
  border-radius: 0.5rem;
  padding: 2rem;
  width: 100%;
  padding-bottom: 5rem;
}
.boxnoborder {
  padding: 2rem;
  width: 100%;
}
.boxnoborder .flex {
  flex-direction: column;
}
.Apptop {
  display: flex;
  flex-direction: row;
}

.outerwrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  padding: 2rem;
  /* height:100%; */
  width: 100%;
  margin: 30px auto;
  background: var(--app-outer-background); /* Old browsers */
}
.input-range__slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fab395 !important;
  border: 1px solid #fab395 !important;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out,
    -webkit-transform 0.3s ease-out;
  width: 1rem;
}
.input-range__track--active {
  background: #fab395 !important;
}
small {
  color: gray;
  font-size: 8px;
  //padding-top: 50px;
  // color: var(--el-small) !important;
}
.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.1rem !important;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}

.input-range__label--max {
  display: none;
}

.input-range__label--min {
  display: none;
}

.input-range__label-container {
  left: -50%;
  position: relative;
  display: none;
}
.bxcell {
  width: 30%;
  float: left;
}

.chart-wrapper {
  margin-bottom: 50px;
}

.calcu-form-wrapper {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 75px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: center;
}

.calcu-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.button-sign-up {
  font-size: 20px;
  margin: 10px;
  border-radius: 5px;
  width: 240px;
  font-weight: bold;
  color: var(--signup-calc-button-text);
  background-color: var(--signup-calc-button-background);
  height: 40px;
  cursor: pointer;
}

.button-sign-up:hover {
  background-color: var(--signup-calc-button-hover-background);
  transition: all 0.2s ease;
}

.metric-table-container {
  width: 100%;
}
.desktop-metric-table {
  border-collapse: collapse;
}

.desktop-metric-table td,
.desktop-metric-table th {
  border: 1px solid var(--table-border);
  padding: 8px;
}

.desktop-metric-table td {
  white-space: nowrap;
  padding: 10px;
}

.desktop-metric-table th,
.desktop-metric-table td {
  text-align: right;
}

.desktop-metric-table th:first-of-type,
.desktop-metric-table td:first-of-type {
  text-align: left;
}

.desktop-metric-table td,
.desktop-metric-table th {
  /* font-weight: normal; */
  /* opacity: 0.8; */
}
td.emphasize-td {
  /* font-size: 18px; */
  font-weight: bold;
  /* text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2); */
}

td.emphasize-td,
th.emphasize-td {
  /* box-shadow: 5px 10px #888888; */
}

.biweekly-tooltip {
  background-color: rgba(255, 255, 255, 0.75);
  padding: 10px;
  font-size: 8px;
  border-radius: 3px;
}

.standard-tooltip {
  margin-top: 20px;
}

.biweekly-tooltip p {
  color: green;
}

.biweekly-tooltip p.tooltip-title {
  color: var(--graph-tooltip);
}

.biweekly-tooltip .standard {
  color: #333;
}

.biweekly-tooltip .tooltip-label {
  color: var(--graph-tooltip);
}

.metric-table {
  width: 100%;
  padding: 20px 10px;
}

.metric-row td {
  background-color: var(--table-td);
}
.metric-row {
  height: 40px;
}

.metric-row:hover {
  background-color: rgba(244, 125, 141, 0.1);
}

.metric-row td:first-of-type {
  text-align: left;
  background-color: var(--table-td-first-background);
  color: var(--table-td-first-text);
}

.metric-row td:last-of-type {
  color: var(--table-td-last-text);
  font-weight: bolder;
}

.metric-row td {
  text-align: right;
  color: #808080;
  font-weight: normal;
}

.metric-table {
  border-collapse: collapse;
  width: 100%;
}

.metric-table .metric-body .metric-row td {
  width: 25%;
}

.metric-table .metric-body .metric-row td:first-of-type {
  width: 50%;
}

.metric-table td,
.metric-table th {
  border: 1px solid var(--table-border);
  padding: 8px;
}

.calcu-form-checkbox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.chart-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.chart-title .subtitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
}

.fixed-message {
  position: fixed;
  bottom: 20px;
  z-index: 1;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fixed-message-top {
  position: fixed;
  z-index: 5;
  height: 80px;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--fixed-msg-background);
}

.fixed-message-top > div {
  background: var(--fixed-msg-background);
  color: var(--fixed-msg-text);
  padding: 10px 20px;
  text-align: center;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fixed-message-top p,
.fixed-message-top a {
  font-size: 18px !important;
  margin-bottom: 0px !important;
}

.fixed-message-top a {
  transition: all 0.2s ease;
  background: var(--fixed-msg-a-background);
  color: var(--fixed-msg-a-text);
  margin-left: 20px;
  border-radius: 3px;
  padding: 3px 5px;
  white-space: nowrap;
}

.fixed-message > div {
  box-shadow: rgba(11, 37, 75, 0.04) 0px 30px 40px -10px,
    rgba(11, 37, 75, 0.05) 0px 22px 22px 0px,
    rgba(11, 37, 75, 0.06) 0px 14px 14px 0px,
    rgba(11, 37, 75, 0.06) 0px 4px 4px 0px,
    rgba(11, 37, 75, 0.06) 0px 2px 2px 0px,
    rgba(11, 37, 75, 0.08) 0px 0px 0px 1px;
  background: var(--fixed-msg-background);
  color: var(--fixed-msg-text);
  padding: 10px 20px;
  text-align: center;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fixed-message p,
.fixed-message a {
  font-size: 12px !important;
  margin-bottom: 0px !important;
}

.fixed-message a {
  transition: all 0.2s ease;
  background: #f5f5f5;
  margin-left: 20px;
  border-radius: 3px;
  padding: 2px 5px;
  white-space: nowrap;
}

.letter {
  width: 80%;
  margin: auto;
  text-align: left;
  padding: 50px 80px;
  margin-top: 20px;
  margin-bottom: 30px;
  border: 1px solid var(--letter-border);
}

.fixed-message a:hover,
.fixed-message-top a:hover {
  background: var(--fixed-msg-a-hover-background) !important;
  color: var(--fixed-msg-a-hover-text) !important;
}

.recharts-wrapper {
  background: var(--graph-background);
}

.charts {
  display: flex;
}
.charts .metric-value {
  font-size: 24px;
  margin-bottom: 50px !important;
}

.dashboard-container {
  -webkit-box-shadow: -1px 2px 63px -12px rgba(209, 209, 209, 1);
  -moz-box-shadow: -1px 2px 63px -12px rgba(209, 209, 209, 1);
  box-shadow: -1px 2px 63px -12px rgba(209, 209, 209, 1);
}

.charts .chart-top-text {
  text-align: right;
  margin: 0px !important;
  padding-right: 30px;
  margin-top: 20px !important;
  margin-bottom: -16px !important;
  z-index: 1;
  position: relative;
}

.charts .chart-bottom-text {
  padding-right: 30px;
  text-align: right;
  margin-top: -25px !important;
  z-index: 1;
  position: relative;
}
.charts .recharts-surface {
  position: absolute;
  left: -30px;
}

.charts .chart-header {
  padding: 20px 30px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: var(--graph-header-background);
}

.charts .chart-header p {
  font-size: 18px !important;
  margin: 0px !important;
  color: var(--graph-header-text);
}

.charts .chart-header p:last-of-type {
  font-size: 30px !important;
}

.charts > div {
  width: 100%;
  background: #f5f5f5;
  border-radius: 3px;
  border: 1px solid var(--graph-border);
}

.charts > div:first-of-type {
  margin-right: 5px;
}
.charts > div:last-of-type {
  margin-left: 5px;
}

.charts span {
  color: #808080;
  font-size: 16px;
}
.charts p {
  color: #000;
}

.charts .metric-chart .biweekly {
  color: var(--table-biweekly-text);
}

.charts .metric-chart {
  padding: 40px 30px;
  padding-bottom: 10px;
}

.charts .metric-chart p:nth-child(2),
.charts .metric-chart p:nth-child(4) {
  margin-bottom: 0px !important;
}

.recharts-responsive-container {
  height: inherit !important;
}
.chart-metric {
  /* display: flex; */
  display: none;
  width: 100%;
  align-items: center;
}

.chart-metric > div {
  margin-top: 40px;
  margin-left: 40px;
  width: 50%;
}

.chart-metric p {
  font-size: 14px !important;
}

.chart-metric p.biweekly {
  color: var(--table-biweekly-text);
}
.chart-metric p strong {
  padding-bottom: 10px;
  display: block;
}
.pre-form-mortgage-term {
  display: flex;
  justify-content: center;
}
.button {
  cursor: pointer;
  &:focus {
    outline: transparent;
  }
}

.button.selected {
  transition: all 0.2s ease;
  color: var(--mortgage-term-selected-text) !important;
  background-color: var(--mortgage-term-selected-background) !important;
}

.carousel__slider .carousel__slide {
  padding-left: 2% !important;
  padding-right: 2% !important;
}
.carousel__slide--focused,
.carousel__slide-focus-ring,
.carousel__slide--focused .carousel__inner-slide,
.carousel__inner-slide:focus {
  outline: none !important;
}

.form-box {
  width: 70%;
  margin: auto;
  margin-top: 5%;
  border: 1px solid var(--pre-question-border);
  text-align: center;
  padding: 5%;
  overflow: hidden;
}

.steps {
  width: 70% !important;
  margin: 0px !important;
  margin-top: 30px !important;
}

.step {
  padding: 10px 15px !important;
  padding-right: 0px !important;
}

.step .title,
.step i.icon {
  z-index: 3;
  position: relative;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 0px !important;
}
.step .content {
  display: flex;
  align-items: center;
}

.step .title {
  font-size: 10px !important;
  display: flex;
  align-items: center;
}

.step i.icon {
  font-size: 20px !important;
}

.ui.steps .active.step:after,
.active.step {
  background: var(--breadcrumb-active-background) !important;
}
.step.active .title,
.step.active i.icon {
  color: var(--breadcrumb-active-text) !important;
}

.funnel-label {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0px !important;
}

.funnel-label:first-of-type {
  margin-top: 5px;
}
/* table responsive */
@media (max-width: 1060px) {
  .desktop-metric-table {
    font-size: 0.9em;
  }
}

@media (min-width: 1001px) {
  .letter p {
    font-size: 18px !important;
  }
}
@media (max-width: 1000px) {
  .App {
    padding: 0px;
    width: 100%;
    margin-left: 0px;
  }

  .charts {
    flex-direction: column;
  }

  .charts > div {
    width: 100%;
  }

  .charts > div:first-of-type,
  .charts > div:last-of-type {
    margin: 0px;
  }

  .charts > div:first-of-type {
    margin-bottom: 20px;
  }

  .recharts-responsive-container {
    width: 100% !important;
  }

  .recharts-responsive-container:first-of-type {
    margin-bottom: 20px;
  }
}
@media (max-width: 920px) {
  .desktop-metric-table {
    font-size: 10px;
  }

  .step i.icon {
    display: none !important;
  }

  .desktop-metric-table td.emphasize-td {
    font-size: 15px;
  }
}

@media (max-width: 320px) {
  .metric-table {
    font-size: 9px;
  }

  .metric-table td.emphasize-td {
    font-size: 13px;
  }
}
/* end for table responsive */

@media (max-width: 768px) {
  .letter {
    padding: 20px 30px;
  }
  .charts span,
  .charts p {
    font-size: 12px;
  }

  .analytics-container {
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    height: inherit !important;
    > div {
      width: 100% !important;
      display: flex !important;
      justify-content: center !important;

      margin: 0px !important;
    }

    .DateRangePicker.DateRangePicker_1 > div {
      display: flex !important;
      justify-content: center;
    }
  }
  .steps {
    width: 70% !important;
  }

  .step .title {
    font-size: 8px !important;
  }

  .chart-title {
    flex-direction: column;
    justify-content: flex-start;
  }

  .chart-metric p {
    font-size: 10px !important;
  }

  .chart-title p:first-of-type {
    margin: 0px !important;
  }

  .chart-title .subtitle {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    opacity: 0.8;
    font-size: 10px;
    text-align: center;
    margin-bottom: 10px;
  }

  .biweekly-tooltip {
    font-size: 5px;
  }

  .outerwrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    padding: 10px;
    /* height:100%; */
    width: 100%;
    margin-top: 30px;
  }
  .bxcell {
    width: 100%;
    float: left;
  }
  .radio {
    color: var(--radio);
  }

  .App {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;

    width: 100%;
    margin-left: 10px;
    float: none;

    align-content: flex-start;
    flex-wrap: wrap;
  }
  .boxnoborder {
    padding: 10px;
    width: 100%;
  }

  .calcu-form {
    width: 90%;
  }

  .App {
    margin-left: 0px;
  }

  .chart-wrapper {
    margin-bottom: 20px;
  }
  .fixed-message-top {
    height: inherit;
    p {
      line-height: 1.5;
    }
  }
}

@media (max-width: 425px) {
  .analytics-container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    > div {
      > div {
        width: 100% !important;
      }

      .analytics-metric {
        width: 90% !important;
      }
    }
  }
  .steps {
    margin-bottom: 10px !important;
    width: 100% !important;
  }

  .form-box {
    width: 100%;
    margin: 0px;
  }

  #root .outerwrapper {
    margin: 0px !important;
    padding: 20px 10px !important;
    width: 100% !important;
  }

  .calcu-form div,
  .calcu-form input {
    width: 100%;
  }

  .calcu-form {
    width: 100%;
  }

  .button-sign-up {
    width: auto;
    align-items: center;
    align-self: center;
    justify-content: center;
    text-align: center;
  }
  .chart-metric div {
    margin-left: 10px;
  }
}

@media (max-width: 700px) {
  .fixed-message {
    bottom: 0px;
  }
  .fixed-message > div {
    flex-direction: column;
    align-items: flex-start;
    bottom: 0px;
    border-radius: 0;
  }

  .fixed-message a,
  .fixed-message p {
    font-size: 10px !important;
  }

  .fixed-message p {
    text-align: left;
  }

  .fixed-message a {
    font-weight: bold;
    margin-left: 10px;
  }

  .fixed-message-top > div {
    flex-direction: column;
    align-items: flex-start;
    bottom: 0px;
    border-radius: 0;
  }

  .fixed-message-top a,
  .fixed-message-top p {
    font-size: 14px !important;
  }

  .fixed-message-top p {
    text-align: left;
  }

  .fixed-message-top a {
    font-weight: bold;
    margin-left: 10px;
  }
}
