$white: #ffffff;
$maroon: #c73d4d;
$maroon-1: #fda501;
$maroon-2: #fda501;
$app-outer-background-default-maroon: linear-gradient(
  to bottom,
  $maroon 0%,
  lighten($maroon, 10%) 100%
);

.red-theme {
  // blog page
  // --blog-border-top: #{$maroon-2};
  --blog-success-text: #{$maroon-2};

  //signup button
  --signup-button-text: #{$white};
  --signup-button-background: #{$maroon};
  --signup-button-hover-background: #{darken($maroon, 5%)};

  //calculator page
  //get started button
  --signup-calc-button-text: #{$white};
  --signup-calc-button-background: #{$maroon};
  --signup-calc-button-hover-background: #{$maroon-2};

  //graph
  --graph-tooltip: #{$maroon};
  --graph-text-standard: #{$maroon-1};
  --graph-text-biweekly: #{$maroon};
  --graph-bar-standard: #{$maroon-1};
  --graph-bar-biweekly: #{$maroon};
  --graph-line-standard: #{$maroon-1};
  --graph-line-biweekly: #{$maroon};
  --graph-background: #{darken($white, 4%)};
  --graph-header-background: #{$maroon};
  --graph-header-text: #{$white};
  --graph-border: #{$maroon-1};

  //mortgage-term option
  --mortgage-term-selected-text: #{$white};
  --mortgage-term-selected-background: #{$maroon-2};

  //metric table
  --table-border: #{lighten($maroon, 10%)};
  --table-emphasize: #{$white};
  --table-emphasize-background: #{$maroon};
  --table-header-background: #{lighten($maroon, 5%)};
  --table-header-background: #{$maroon};
  --table-body-background: #{$white};
  --table-body-text: #{$maroon};
  --table-td: #{$white};
  --table-td-first-text: #{$white};
  --table-td-first-background: #{lighten($maroon, 10%)};
  --table-td-last-text: #{$maroon};
  --table-biweekly-text: #{$maroon};

  --confirm-outline: var(--maroon-1);

  // calculator header
  --app-title: #{$white};
  --app-body-text: #{$white};
  --app-body-background: #{$maroon};
  --app-outer-background: #{$app-outer-background-default-maroon} !important;

  //pre question buttons
  --button-back-background: #{$white};
  --button-back-text: #{$maroon};
  --button-continue-background: #{$white};
  --button-continue-text: #{$maroon};

  //breadcrumbs
  --breadcrumb-active-background: #{$maroon};
  --breadcrumb-active-text: #{$white};

  // sticky header message
  --fixed-msg-a-background: #{$maroon};
  --fixed-msg-a-text: #{$white};
  --fixed-msg-a-hover-background: #{$maroon-2};
  --fixed-msg-a-hover-text: #{$white};
  --fixed-msg-background: #{$white};
  --fixed-msg-text: #{$maroon};

  --el-small: #{$white};
  --letter-border: #{$white};

  --pre-question-border: #{$white};

  --radio: #{$white};
}
