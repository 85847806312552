$white: #ffffff;
$green: #16ab39;
$green-1: darken($green, 5%);
$green-2: darken($green, 1%);
$maroon-2: #e3828e;

.green-theme {
  // blog page
  // --blog-border-top: #{$green-1};
  --blog-success-text: #{$green-1};

  //signup button
  --author-color: #{$green};
  --signup-button-text: #{$white};
  --signup-button-background: #{$green};
  --signup-button-hover-background: #{darken($green, 5%)};

  //calculator page
  //get started button
  --signup-calc-button-text: #{$white};
  --signup-calc-button-background: #{$green-1};
  --signup-calc-button-hover-background: #{$green};

  //graph
  --graph-tooltip: #{$green-1};
  --graph-text-standard: #{$maroon-2};
  --graph-text-biweekly: #{$green};
  --graph-bar-standard: #{$maroon-2};
  --graph-bar-biweekly: #{$green};
  --graph-line-standard: #{$maroon-2};
  --graph-line-biweekly: #{$green};
  --graph-background: #{darken($white, 4%)};
  --graph-header-background: #{$green};
  --graph-header-text: #{$white};
  --graph-border: #{$green};

  //mortgage-term option
  --mortgage-term-selected-text: #{$white};
  --mortgage-term-selected-background: #{$green};

  //metric table
  --table-border: #{$green-1};
  --table-emphasize: #{$white};
  --table-emphasize-background: #{$green};
  --table-header-background: #{lighten($green, 5%)};
  --table-body-background: #{$white};
  --table-body-text: #{$green};
  --table-td: #{$white};
  --table-td-first-text: #{$white};
  --table-td-first-background: #{lighten($green, 5%)};
  --table-td-last-text: #{$green};
  --table-biweekly-text: #{$green};

  --confirm-outline: var(--green-1);

  // calculator header
  --app-title: #{$white};
  --app-body-text: #{$white};
  --app-body-background: #{$green};
  --app-outer-background: #{$green-1};

  //pre question buttons
  --button-back-background: #{$green};
  --button-back-text: #{$white};
  --button-continue-background: #{$green};
  --button-continue-text: #{$white};

  //breadcrumbs
  --breadcrumb-active-background: #{$green};
  --breadcrumb-active-text: #{$white};

  // sticky header message
  --fixed-msg-a-background: #{$green};
  --fixed-msg-a-hover-background: #{$white};
  --fixed-msg-a-text: #{$white};
  --fixed-msg-a-hover-text: #{$green};
  --fixed-msg-background: #{$white};
  --fixed-msg-text: #{$green};

  --el-small: #{$green};
  --letter-border: #{$white};

  --pre-question-border: #{$white};

  --radio: #{$green};
}
